import React from 'react';
import heroImage from '../assets/image.jpeg';
import mobileBackground from '../assets/stars.png';

const Hero = () => {
  return (
    <div className="min-h-screen relative bg-black overflow-hidden">
      {/* Mobile background */}
      <div
        className="absolute inset-0 md:hidden"
        style={{
          backgroundImage: `url(${mobileBackground})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <div className="absolute inset-0 bg-black/40 backdrop-blur-[2px]" />
      </div>

      {/* Desktop background image */}
      <div
        className="absolute inset-0 hidden md:block"
        style={{
          backgroundImage: `url(${heroImage})`,
          backgroundPosition: 'right center',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
        }}
      />

      {/* Ultra-smooth desktop gradient overlay */}
      <div className="absolute inset-0 hidden md:block">
        {/* Fine-tuned gradient starting at 20% */}
        <div 
          className="absolute inset-0"
          style={{
            background: `linear-gradient(90deg, 
              rgba(0,0,0,1) 20%,
              rgba(0,0,0,0.95) 21.5%,
              rgba(0,0,0,0.90) 23%,
              rgba(0,0,0,0.85) 24.5%,
              rgba(0,0,0,0.80) 26%,
              rgba(0,0,0,0.75) 27.5%,
              rgba(0,0,0,0.70) 29%,
              rgba(0,0,0,0.65) 30.5%,
              rgba(0,0,0,0.55) 32%,
              rgba(0,0,0,0.45) 33%,
              rgba(0,0,0,0.35) 34%,
              rgba(0,0,0,0.25) 35%,
              rgba(0,0,0,0.15) 36%,
              rgba(0,0,0,0.05) 37%,
              transparent 38%
            )`,
          }}
        />
        
        {/* Very subtle blur layer for final smoothing */}
        <div 
          className="absolute inset-0 backdrop-blur-[0.2px]"
          style={{
            maskImage: 'linear-gradient(to right, black 20%, transparent 38%)',
            WebkitMaskImage: 'linear-gradient(to right, black 20%, transparent 38%)',
          }}
        />
      </div>

      {/* Content container */}
      <div className="container-width min-h-screen relative z-10">
        <div className="flex min-h-screen items-center">
          {/* Text content */}
          <div className="w-full md:w-2/5 px-6 md:pl-0 md:pr-4 animate-fade-in">
            {/* Mobile layout */}
            <div className="md:hidden pt-24 pb-12">
              <h1 className="text-4xl font-bold text-white mb-6 tracking-tight drop-shadow-lg">
                Alan K Mathew
              </h1>
              <p className="text-lg text-gray-100 leading-relaxed text-justify hyphens-auto drop-shadow-md backdrop-blur-sm bg-black/20 p-4 rounded-lg">
              As a Computer Science Master's student, I integrate strong technical knowledge with direct experience in developing secure software and hardware systems. My expertise lies in crafting practical and innovative solutions that address complex challenges.
              </p>
            </div>

            {/* Desktop layout */}
            <div className="hidden md:block">
              <h1 className="text-5xl lg:text-6xl font-bold text-white mb-6 tracking-tight">
                Alan K Mathew
              </h1>
              <p className="text-xl text-gray-200 leading-relaxed text-justify hyphens-auto">
              As a Computer Science Master's student, I integrate strong technical knowledge with direct experience in developing secure software and hardware systems. My expertise lies in crafting practical and innovative solutions that address complex challenges.
              </p>
            </div>
          </div>
          
          {/* Empty space for the photo - desktop only */}
          <div className="hidden md:block md:w-3/5" />
        </div>
      </div>
    </div>
  );
};

export default Hero;