import React from 'react';
import { Calendar } from 'lucide-react';

// const ExperienceCard = ({ title, company, period, location, description, contact }) => (
//   <div className="relative pl-4 border-l-4 border-blue-500 bg-white rounded-lg shadow-md p-6 mb-8 hover:shadow-lg transition-all duration-300 ease-in-out hover:-translate-y-1">
//     <div className="flex flex-wrap justify-between items-start mb-2">
//       <h3 className="text-xl font-bold text-gray-900">{title}</h3>
//       <span className="text-gray-500 flex items-center gap-1 text-sm">
//         <Calendar size={16} /> {period}
//       </span>
//     </div>
//     <div className="text-lg font-semibold text-blue-600 mb-2">{company}</div>
//     <div className="text-gray-500 mb-4">{location}</div>
//     <ul className="space-y-2 mb-4">
//       {description.map((item, i) => (
//         <li key={i} className="text-gray-600 flex items-start">
//           <span className="mr-2 text-blue-500">•</span>
//           {item}
//         </li>
//       ))}
//     </ul>
//     <div className="text-sm text-gray-500 italic">
//       Contact: {contact}
//     </div>
//   </div>
// );
const ExperienceCard = ({ title, company, period, location, description, contact }) => (
  <div className="relative pl-4 border-l-4 border-gray-500 bg-white rounded-lg shadow-md p-6 mb-8 hover:shadow-lg transition-all duration-300 ease-in-out hover:-translate-y-1">
    <div className="flex flex-wrap justify-between items-start mb-2">
      <h3 className="text-xl font-bold text-gray-900">{title}</h3>
      <span className="text-gray-500 flex items-center gap-1 text-sm">
        <Calendar size={16} /> {period}
      </span>
    </div>
    <div className="text-lg font-semibold text-gray-700 mb-2">{company}</div>
    <div className="text-gray-500 mb-4">{location}</div>
    <ul className="space-y-2 mb-4">
      {description.map((item, i) => (
        <li key={i} className="text-gray-600 flex items-start">
          <span className="mr-2 text-gray-500">•</span>
          {item}
        </li>
      ))}
    </ul>
  </div>
);

const Experience = () => {
  const experiences = [
    {
      title: "Master's Thesis Student",
      company: "FRESENIUS MEDICAL CARE",
      period: "10/2024 - 03/2025",
      location: "Schweinfurt, Germany",
      description: [
        "Designing and implementing a unified authentication system for medical devices with secure mobile apps on iOS and Android platforms",
        "Technology used include mTLS, Bluetooth, X.509 v3 certificate, NFC and secure storage",
        "Developed secure certificate management system for access control using X.509 extensions"
      ],
    },
    {
      title: "Working Student IC-VSM Software Functions",
      company: "FRESENIUS MEDICAL CARE",
      period: "11/2023 - 10/2024",
      location: "Schweinfurt, Germany",
      description: [
        "Automated tasks using Python and Velocity for Polarion",
        "Developed efficient algorithms for complex data processing tasks",
        "Developed and tested multiple tools for various use cases in Polarion",
        "Ensured compliance with industry standards and coding guidelines",
        "Assisted in developing coding conventions and configured static code analysis tools"
      ],
    },
    {
      title: "Senior Process Executive",
      company: "THANAL CONSULTANCY",
      period: "06/2019 - 02/2023",
      location: "Wayanad, India",
      description: [
        "Preparing and executing staffing strategies to meet client needs",
        "Provided technical support for employees",
        "Managing payroll and benefits administration for employees"
      ],
    },
    {
      title: "Technical Assistant",
      company: "ELISRUN TECHNOLOGIES PRIVATE LIMITED",
      period: "01/2019 - 06/2019",
      location: "Banglore, India",
      description: [
        "Troubleshooting and resolving computer hardware and software issues",
        "Collaborating with other team members to develop and implement technology-related projects",
        "Installation, configuration, and maintenance of computer systems and networks"
      ],
    }
  ];

  return (
    <section id="experience" className="section-padding bg-gray-50">
      <div className="container-width">
        <h2 className="section-title">Experience</h2>
        <div className="space-y-8">
          {experiences.map((exp, index) => (
            <ExperienceCard key={index} {...exp} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Experience;